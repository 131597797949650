<template>
  <v-container class="full-height">
    <v-row class="full-height">
      <v-col
        ref="targetInfiniteDiv"
        class="full-height"
        style="position: relative">
        <div
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="busy">
          <div
            v-for="blog in blogs"
            :key="blog.id">
            <BlogCard :blog="blog"></BlogCard>
            <v-divider class="my-4"></v-divider>
          </div>
        </div>
        <ScrollLoding :loading="busy"></ScrollLoding>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlogCard from '@/components/Blog/BlogCard'
import ScrollLoding from '@/components/base/scrollLoding'

export default {
  name: 'Blog',
  inject: ['theme'],
  components: {
    BlogCard,
    ScrollLoding
  },

  data: () => ({
    limit: 7,
    offset: 1,
    count: 0,
    busy: false,
    blogs: []
  }),

  computed: {
    isAllData () {
      return this.blogs.length >= this.count
    }
  },

  async created () {
    await this.initData()
  },

  watch: {
    $route () {
      this.listBlogs()
    }
  },

  methods: {
    async initData () {
      await this.addBlogs()

      const dom = this.$refs.targetInfiniteDiv
      const coverHeight = dom.clientHeight
      let hasCover = dom.lastChild.offsetTop + dom.lastChild.clientHeight >= coverHeight
      while (!this.isAllData && !hasCover) {
        await this.addBlogs()
        hasCover = dom.lastChild.offsetTop + dom.lastChild.clientHeight >= coverHeight
      }
    },

    async loadMore () {
      if (this.isAllData) {
        return
      }
      this.busy = true
      await this.addBlogs()
      this.busy = false
    },

    async addBlogs () {
      const data = await this.$http.Blog.list(Object.assign({
        isPublish: true,
        limit: this.limit,
        offset: this.offset++
      }, this.$route.query))
      this.count = data && data.count ? data.count : 0
      this.blogs.push(...(data && data.rows ? data.rows : []))
    },

    listBlogs () {
      this.blogs = []
      this.offset = 1
      this.addBlogs()
    },

    goDetail (id) {
      this.$router.push({ name: 'blog_detail', params: { id } })
    }
  }
}
</script>
