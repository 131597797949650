<template>
  <article @dblclick="goDetail">
    <div class="article-info">
      <h3 class="article-title hasBeforeIcon hasHoverUnderline" :title="blog.title" @click="goDetail">
        {{ blog.title }}
      </h3>
      <p class="mb-0 text-subtitle-3 font-weight-regular">{{ this.createdDateText }}</p>
    </div>

    <div class="article-summary" style="margin-top: 6px">
      {{ blog.summary }}
      <span class="article-more-read font-weight-bold text-subtitle-2 font-weight-regular hasHoverUnderline" @click="goDetail">继续阅读 >></span>
    </div>

    <CategoryAndLabelCard
      :Category="blog.Category"
      :Labels="blog.Labels"
      @toQueryCategory="toQueryCategory"
      @toQueryLabel="toBeAdd">
    </CategoryAndLabelCard>
  </article>
</template>

<script>
import CategoryAndLabelCard from '@/components/base/CategoryAndLabelCard'

export default {
  name: 'BlogCard',
  components: {
    CategoryAndLabelCard
  },
  props: {
    blog: {
      default: ''
    }
  },
  computed: {
    createdDateText () {
      const date = this.blog.createdAt.split(' ')
      const day = date[0].split('-')
      return `${day[0]}年${day[1]}月${day[2]}日 ${date[1]}`
    }
  },
  data () {
    return {
    }
  },
  methods: {
    goDetail () {
      const router = this.$router.resolve({
        name: 'blog_detail',
        params: {
          id: this.blog.id
        }
      })
      window.open(router.href, '_blank')
    },

    toQueryCategory (categoryId) {
      this.$router.push({
        name: 'blog_index',
        query: {
          category_id: categoryId
        }
      })
    },

    toBeAdd () {
      this.$dialog.message.info('待补充此功能 (●ˇ∀ˇ●)', {
        position: 'top',
        timeout: 1500
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  article {
    .article-title {
      cursor: pointer;
      &:before {
        color: #1976d2;
        content: "\f0f4";
      }
    }
  }
</style>
